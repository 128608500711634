<template>
	<DateInput
		ref="birthDate"
		:value="value"
		:label="labelBirthDateComp"
		:validation-rules="{
			required: true,
			isValidDate: true,
			beforeToday: today,
			isUnderNineteen: isUnderNineteenRequiredComp,
			isTooOld: true
		}"
		:validation-messages="{
			required: requiredErrorMsgComp,
			isValidDate: $t('error.birthdateIsValidDate'),
			beforeToday: beforeTodayMsg,
			isUnderNineteen: isUnderNineteenError,
			isTooOld: $t('error.birthdateIsValidDate')
		}"
		:placeholder="placeholderBirthDateComp"
		:automation-id="getAutomationId('birthdate')"
		aria-required="true"
		@blur="updateBirthDate"
		@input="updateBirthDateInput"
	/>
</template>

<script>
/**
 * This component is used to enter a persons birth date.
 */
import Vue from 'vue';
import IdMixin from '@/mixins/id';
import Component from 'vue-class-component';
import DateInput from '@/components/common/base/BaseDateInput.vue';
import { required } from 'vee-validate/dist/rules';
import { extend } from 'vee-validate';
import isValid from 'date-fns/isValid';
import parse from 'date-fns/parse';
import { isUnderNineteen } from '@/utils/date';

extend('required', required);
/**
 * This extended validation rule is used to check if the date entered in the component is before today.
 */
extend('beforeToday', {
	validate: (value, { min }) => {
		var dateParts = value.split('/');
		var dateObject = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
		return dateObject <= min;
	},
	params: ['min'],
	message: '{_field_} Should be before today.'
});
/**
 * This extended validation rule is used to check if the date entered is a valid date.
 */
extend('isValidDate', {
	validate: (value) => {
		let dateFormat = 'dd/MM/yyyy';
		const date = parse(value, dateFormat, new Date());
		return isValid(date) && value.length === dateFormat.length;
	}
});

extend('isUnderNineteen', {
	validate: (value) => {
		return isUnderNineteen(value) ? false : true;
	}
});
extend('isTooOld', {
	validate: (value) => {
		let today = new Date();
		let dob = new Date(value.split(/\//).reverse().join('/'));
		let yearsDiff = today.getFullYear() - dob.getFullYear();
		if (yearsDiff > 120) {
			return false;
		}
		return true;
	}
});
// @vue/component
@Component({
	name: 'BirthDate',
	components: {
		DateInput
	},
	mixins: [IdMixin],
	props: {
		/**
		 * value is the value that will be used by this component to populate the input fields.
		 */
		value: {
			type: String,
			default: null
		},
		/**
		 * This prop can be passed in, or it defaults to the current date. It is used by the Beneficary age component(The date field on this component) to check if the date passed in is before today.
		 */
		today: {
			type: Date,
			default: () => new Date()
		},
		/**
		 * This prop is used to populate the label of the birth date component.
		 */
		labelBirthDate: {
			type: String,
			default: null
		},
		/**
		 * Test automation id is used by the test automation Id suite to identify specfic components.
		 */
		automationId: {
			type: String,
			default: '',
			required: true
		},
		/**
		 * This prop is passed in from parent component to display an error message when the component is required
		 * but not given a value.
		 */
		requiredErrorMsg: {
			type: String,
			default: null
		},
		/**
		 * This prop is used to pass in custom error messages for the Before Today rule.
		 */
		beforeTodayErrorMsg: {
			type: String,
			default: null
		},
		/**
		 * Value to set error message if the person is under 19. Mainly used for trustees
		 */
		isUnderNineteenError: {
			type: String,
			default: null
		},
		/**
		 * this props is used to populatr the placeholder of the birth date component.
		 */
		placeholder: {
			type: String,
			default: null
		},
		/**
		 * The French labels are different for adults and children.  By default we show the adult
		 * labels.  If we need to show the children labels, set this prop to true.
		 */
		child: {
			type: Boolean,
			default: false
		}
	}
})
export default class BirthDate extends Vue {
	/**
	 * This function retrieves the member to be displayed from the value prop to populate the input fields on this component.
	 * If there is no date retrieve the fields will be left empty.
	 */
	get member() {
		return this.value ? this.value : {};
	}

	/**
	 * These computed props check if an error message was passed in,
	 * if it wasn't then it used a default error message.
	 */
	get labelBirthDateComp() {
		return this.labelBirthDate ? this.labelBirthDate : this.$t('label.birthdate');
	}
	get requiredErrorMsgComp() {
		return this.requiredErrorMsg ? this.requiredErrorMsg : this.$t('error.birthdateRequired');
	}

	get beforeTodayMsg() {
		return this.beforeTodayErrorMsg
			? this.beforeTodayErrorMsg
			: this.$t('error.birthdateBeforeToday');
	}

	get placeholderBirthDateComp() {
		return this.placeholder ? this.placeholder : this.$t('placeholder.birthDate');
	}

	get isUnderNineteenRequiredComp() {
		return this.isUnderNineteenError ? true : false;
	}

	/**
	 * This function emits to the parent component the value entered in the birth date field.
	 */
	updateBirthDate(dateOfBirth) {
		this.$emit('blur', dateOfBirth);
	}
	/**
	 *  * This function emits to the parent component the value entered in the birth date field.
	 */
	updateBirthDateInput(dateOfBirth) {
		// prevent the cursor jumping on edit
		if (dateOfBirth.length === 10) {
			this.$emit('input', dateOfBirth);
		}
	}
}
</script>

<i18n>
{
  "en": {
	"label": {
		"birthdate": "Date of Birth"
	},
	"error": {
		"birthdateRequired": "Please provide your birth date.",
		"birthdateBeforeToday": "The birth date you entered is in the future. Please check your date of birth.",
		"birthdateIsValidDate": "Please enter a valid birth date."
    },
    "placeholder": {
        "birthDate": "dd/mm/yyyy"
    }
   },
  "fr": {
	"label": {
		"birthdate": "Date de naissance"
	},
	"error": {
		"birthdateRequired": "Veuillez entrer votre date de naissance.",
		"birthdateBeforeToday": "La date de naissance que vous avez entrée est située dans le futur. Veuillez vérifier la date de naissance.",
		"birthdateIsValidDate": "Veuillez entrer une date de naissance valide."
    },
    "placeholder": {
        "birthDate": "jj/mm/aaaa"
    }
  }
}
</i18n>
