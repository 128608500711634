var render = function render(){var _vm=this,_c=_vm._self._c;return _c('DateInput',{ref:"birthDate",attrs:{"value":_vm.value,"label":_vm.labelBirthDateComp,"validation-rules":{
		required: true,
		isValidDate: true,
		beforeToday: _vm.today,
		isUnderNineteen: _vm.isUnderNineteenRequiredComp,
		isTooOld: true
	},"validation-messages":{
		required: _vm.requiredErrorMsgComp,
		isValidDate: _vm.$t('error.birthdateIsValidDate'),
		beforeToday: _vm.beforeTodayMsg,
		isUnderNineteen: _vm.isUnderNineteenError,
		isTooOld: _vm.$t('error.birthdateIsValidDate')
	},"placeholder":_vm.placeholderBirthDateComp,"automation-id":_vm.getAutomationId('birthdate'),"aria-required":"true"},on:{"blur":_vm.updateBirthDate,"input":_vm.updateBirthDateInput}})
}
var staticRenderFns = []

export { render, staticRenderFns }