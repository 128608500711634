<!-- *************************************************************************
	TEMPLATE
	************************************************************************* -->

<template>
	<div>
		<BaseMaskedInput
			v-bind="$attrs"
			:value="maskedValue"
			:mask="mask"
			:optional-label="$t('format')"
			:data-test-automation-id="getAutomationId('dateinput')"
			:data-tracking-id="getAutomationId('dateinput')"
			:automation-id="getAutomationId('dateinput')"
			@input="onInput"
			@blur="onBlur"
		>
		</BaseMaskedInput>
	</div>
</template>

<!-- *************************************************************************
	SCRIPT
	************************************************************************* -->

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import IdMixin from '@/mixins/id';
import BaseMaskedInput from '@/components/common/base/BaseMaskedInput';

/**
 * This component is responsible to render date input.
 */
// @vue/component
@Component({
	name: 'BaseDateInput',
	components: {
		BaseMaskedInput
	},
	mixins: [IdMixin],
	inheritAttrs: false,
	props: {
		value: {
			type: String,
			default: null
		},
		mask: {
			default: '##/##/####',
			type: String
		},
		dateFormat: {
			default: 'dd/MM/yyyy',
			type: String
		},
		automationId: {
			type: String,
			required: true
		}
	}
})
export default class BaseDateInput extends Vue {
	savedMaskedValue = null;

	/**
	 * Returns the mask value.
	 */
	get maskedValue() {
		this.savedMaskedValue = this.value;
		return this.value;
	}
	/**
	 * Handle blur event.
	 */
	onBlur() {
		const date = this.savedMaskedValue;
		this.$emit('blur', date);
	}

	/**
	 * Handle input event
	 * @param maskedValue The masked value.
	 */
	onInput(maskedValue) {
		this.savedMaskedValue = maskedValue;
		this.$emit('input', maskedValue);
	}
}
</script>
<i18n>
{
	"en": {
		"format": "(DD / MM / YYYY)"
	},
	"fr": {
		"format": "(JJ / MM / AAAA)"
	}
}
</i18n>
