<!-- *************************************************************************
	Form Header
	************************************************************************* -->

<template>
	<div :class="`my-4 ${className}`">
		<h1>{{ title }}</h1>
		<p class="text">{{ secondaryTitle }}</p>
	</div>
</template>

<!-- *************************************************************************
	SCRIPT
	************************************************************************* -->

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import IdMixin from '@/mixins/id';
// @vue/component
@Component({
	name: 'FormHeader',
	mixins: [IdMixin],
	props: {
		// The main title on top of the form
		title: {
			type: String,
			required: true
		},
		// A secondary (smaller) title on the right of the main title
		secondaryTitle: {
			type: String,
			default: '',
			required: false
		},
		// The automation ID is used as prefix for the test automation ID and the tracking ID
		automationId: {
			type: String,
			required: true
		},
		className: {
			type: String,
			default: ''
		}
	}
})
export default class FormHeader extends Vue {}
</script>

<!-- *************************************************************************
	STYLE
	************************************************************************* -->

<style lang="scss" scoped>
.title {
	font-size: 32px;
}
.text {
	font-size: 21px;
	color: $black-text;
	font-family: 'Lato', sans-serif;
	font-weight: 300;
	padding-right: 0.2rem;
}
</style>
